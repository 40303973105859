import type { LinkProps as RemixLinkProps } from '@remix-run/react'
import { Link as RemixLink } from '@remix-run/react'
import cx from 'classnames'

type LinkVariant = 'text' | 'button-fill'

export interface LinkProps extends RemixLinkProps {
  variant?: LinkVariant
  disabled?: boolean
}

export function Link({
  variant = 'text',
  children,
  className,
  disabled,
  ...rest
}: LinkProps) {
  return (
    <RemixLink
      {...rest}
      className={cx(
        classesByVariant[variant],
        // Icon styles
        '[&_svg]:inline-block [&_svg]:align-middle',
        className,
        {
          'opacity-40 cursor-not-allowed, pointer-events-none': disabled,
        }
      )}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
    >
      {children}
    </RemixLink>
  )
}

const classesByVariant: Record<LinkVariant, string | string[]> = {
  text: [
    // Icon styles
    '[&_svg]:text-neutral-200 [&_svg:last-child]:ml-1',
  ],
  'button-fill': [
    'hocus:shadow-button-hocus shadow-button rounded-md bg-neutral-500 px-4 py-[7px] text-center font-lato text-sm font-medium text-white transition-colors hocus:bg-neutral-600 inline-block',
    // Icon styles
    '[&_svg]:text-neutral-200 [&_svg:last-child]:ml-2.5',
  ],
}
